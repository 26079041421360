import { HydratedRouter } from 'react-router/dom';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import LocizeBackend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import LastUsed from 'locize-lastused';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next/client';
import { i18nextOptions, locizeOptions } from '~/lib/i18next';

const isProd = window.ENV.NODE_ENV === 'production';
const isPlaywright = window.ENV.ENVIRONMENT === 'playwright';
const isDev = window.ENV.NODE_ENV === 'development';

Sentry.init({
  dsn: 'https://e1dd1d4a6a4343d3a4a12690c9f1c8d8@o218934.ingest.us.sentry.io/4505578812014592',
  integrations: [],
  environment: window.ENV.ENVIRONMENT,
  enabled: isProd && !isPlaywright,
});

async function hydrate() {
  if (!i18next.isInitialized) {
    if (isDev) {
      i18next.use(LastUsed);
    }

    if (isPlaywright) {
      i18next.use(HttpBackend);
    } else {
      i18next.use(LocizeBackend).use(locizePlugin);
    }

    await i18next
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        ...i18nextOptions,
        ns: getInitialNamespaces(),
        backend: isPlaywright ? { loadPath: '/locales/{{lng}}/{{ns}}.json' } : locizeOptions,
        detection: {
          order: ['htmlTag'],
          caches: [],
        },
        locizeLastUsed: locizeOptions,
        saveMissing: isDev,
      });

    startTransition(() => {
      hydrateRoot(
        document,
        <I18nextProvider i18n={i18next}>
          <StrictMode>
            <HydratedRouter />
          </StrictMode>
        </I18nextProvider>,
      );
    });
  }
}

hydrate().catch(console.error);
